import React from 'react';
import Layout from '../components/Layout';
import { Link } from 'react-router-dom';
import '../styles/Home.css';

// Import your images (you'll need to add these images to your assets)
import aboutImage from '../assets/about-thumbnail.png';
import projectsImage from '../assets/projects-thumbnail.png';
import blogImage from '../assets/blog-thumbnail.png';

export default function Home() {
  return (
    <Layout>
      <div className="home-container">
        <div className="content-wrapper">
          <div className="home-header">
            <h1 className="title">Marcus Webb</h1>
            <p className="subtitle">
              Machine Learning Engineer & Team Lead
            </p>
            <p className="description">
              Building intelligent systems and leading ML teams to solve complex problems.
            </p>
          </div>

          <div className="card-grid">
            <Link to="/about" className="card">
              <div className="card-image">
                <img src={aboutImage} alt="About Me" />
              </div>
              <div className="card-content">
                <h2 className="card-title">About Me</h2>
                <p className="card-description">Learn about my experience and expertise in ML engineering and leadership.</p>
              </div>
            </Link>

            <Link to="/projects" className="card">
              <div className="card-image">
                <img src={projectsImage} alt="Projects" />
              </div>
              <div className="card-content">
                <h2 className="card-title">Projects</h2>
                <p className="card-description">Explore my portfolio of machine learning and software projects.</p>
              </div>
            </Link>

            <Link to="/blog" className="card">
              <div className="card-image">
                <img src={blogImage} alt="Blog" />
              </div>
              <div className="card-content">
                <h2 className="card-title">Blog</h2>
                <p className="card-description">Read my thoughts on ML, engineering, and technology.</p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
}
