import { Link } from 'react-router-dom';
import '../../styles/Projects.css';

export default function ProjectCard({ project }) {
  return (
    <Link to={project.link} className="project-card">
      <article>
        <div className="project-card-image">
          <img src={project.image} alt={project.title} />
        </div>
        <div className="project-card-content">
          <h2 className="project-card-title">
            <span className="title-text">{project.title}</span>
          </h2>
          <p className="project-card-tagline">{project.tagline}</p>
          {project.description && (
            <p className="project-card-description">{project.description}</p>
          )}
        </div>
        <div className="project-card-meta">
          <div className="project-card-arrow">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
            </svg>
          </div>
        </div>
      </article>
    </Link>
  );
} 