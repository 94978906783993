import Layout from '../components/Layout';
import '../styles/DragonSlayer.css';
import dragonSlayerLogo from '../assets/dragon-slayer.jpeg';

export default function DragonSlayer() {
    return <Layout>
        <div className="dragon-slayer-container">
            <section className="game-info">
                <img 
                    src={dragonSlayerLogo} 
                    alt="Dragon Slayer Game Logo" 
                    className="game-logo"
                />
                <h1>Dragon Slayer</h1>
                <p className="tagline">A game about taming dragons</p>
                
                <div className="description">
                    <p>
                        Dragon Slayer is a game about taming dragons. It is a single player game that 
                        is played on Android devices and available on the Google Play Store.
                    </p>
                    <p>
                        The game is built using Kotlin and Jetpack Compose. My son Evan and I built this 
                        game in our spare time. Expect sequels...
                    </p>
                    
                    <a 
                        href="https://play.google.com/apps/testing/com.webbdev.dragonslayer"
                        className="play-store-link"
                    >
                        Dragon Slayer on Google Play
                    </a>
                    <a href="#privacy-policy" className="privacy-link">
                        Privacy Policy
                    </a>
                </div>
            </section>

            <section id="privacy-policy" className="privacy-policy">
                <h2>Privacy Policy</h2>
                <div className="policy-content">
                    <p>
                        Dragon Slayer is designed with your child's privacy in mind. We are committed to 
                        providing a safe, secure gaming experience for players of all ages.
                    </p>
                    
                    <h3>Information Collection</h3>
                    <p>
                        Dragon Slayer does not collect, store, or transmit any personal information. 
                        This means:
                    </p>
                    <ul>
                        <li>No personal data is collected</li>
                        <li>No location tracking</li>
                        <li>No advertising identifiers</li>
                        <li>No analytics data</li>
                    </ul>

                    <h3>Offline Gameplay</h3>
                    <p>
                        The game is completely playable offline. Once downloaded, no internet 
                        connection is required to play.
                    </p>

                    <h3>Contact</h3>
                    <p>
                        If you have any questions about our privacy practices, please contact us 
                        through the Google Play Store listing, or send an email to 
                        <a href="mailto:marcus.webb@webbdev.llc">marcus.webb@webbdev.llc</a>.
                    </p>

                    <p className="last-updated">
                        Last updated: {new Date().toLocaleDateString()}
                    </p>
                </div>
            </section>
        </div>
    </Layout>
}
