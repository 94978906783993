import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Header.css';
import { ReactComponent as Logo } from '../assets/logo.svg';

const Header = () => {
  return (
    <header className="header">
      <div className="header-content">
        <div className="logo">
          <Link to="/">
            <Logo className="logo-image" />
            <span>Webb Development</span>
          </Link>
        </div>
        <nav className="nav-links">
          <Link to="/about">About</Link>
          <Link to="/projects">Projects</Link>
          <Link to="/blog">Blog</Link>
        </nav>
      </div>
    </header>
  );
};

export default Header;
