export default function TableOfContents({ toc, onHeadingClick }) {
  return (
    <nav className="table-of-contents">
      <h2>Table of Contents</h2>
      <ul>
        {toc.map(({ id, text, level }) => (
          <li
            key={id}
            style={{ paddingLeft: `${(level - 1) * 1}rem` }}
            onClick={() => onHeadingClick(id)}
          >
            {text}
          </li>
        ))}
      </ul>
    </nav>
  );
}