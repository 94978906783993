import { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import BlogCard from '../components/blog/BlogCard';
import { fetchBlogPosts } from '../services/blogService';
import '../styles/Blog.css';

export default function Blog() {
  const [posts, setPosts] = useState([]);
  const BUCKET_URL = process.env.REACT_APP_BUCKET_URL;

  useEffect(() => {
    const loadPosts = async () => {
      try {
        const postsData = await fetchBlogPosts(BUCKET_URL);
        setPosts(postsData);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
        setPosts([]);
      }
    };

    loadPosts();
  }, [BUCKET_URL]);

  return (
    <Layout>
      <div className="blog-container">
        <div className="blog-content">
          <div className="blog-intro">
            <h1 className="blog-intro-title">Blog & Insights</h1>
            <p className="blog-intro-text">
              Welcome to my corner of the web where I share thoughts on machine learning
              engineering practices and related topics. Through these posts, I aim to 
              document my journey, share valuable insights, and contribute to the broader 
              tech community. Whether you're a fellow engineer, a tech leader, or just 
              curious about ML, I hope you'll find something interesting here.
            </p>
          </div>
          <div className="blog-grid">
            {posts.map((post) => (
              <BlogCard key={post.filename} post={post} />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}
