import { marked } from 'marked';

export const fetchBlogPosts = async (bucketUrl) => {
    console.log('Starting fetch request...');
    const response = await fetch(`${bucketUrl}/index.json`, {
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        },
    });

    if (!response.ok) throw new Error('Failed to fetch posts');

    const data = await response.json();

    if (!data.posts || !Array.isArray(data.posts)) {
        throw new Error('Invalid posts data - expected an array');
    }

    return data.posts.map(post => ({
        ...post,
        markdown_url: `${bucketUrl}/releases/${data.release}/posts/${post.filename}`,
        image_url: post.image ? `${bucketUrl}/releases/${data.release}${post.image}` : null
    }));
};


export const fetchPostContent = async (bucketUrl, slug) => {
    // First get the metadata from index.json
    const indexResponse = await fetch(`${bucketUrl}/index.json`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });
    
    if (!indexResponse.ok) throw new Error('Failed to fetch index');
    const data = await indexResponse.json();
    console.log('Using release:', data.release);
    
    const postInfo = data.posts.find(p => p.filename === `${slug}.md`);
    if (!postInfo) throw new Error('Post not found');
  
    // Construct the markdown URL using the release number
    const markdownUrl = `${bucketUrl}/releases/${data.release}/posts/${postInfo.filename}`;
    
    const contentResponse = await fetch(markdownUrl);
    if (!contentResponse.ok) throw new Error('Failed to fetch post content');
    const markdown = await contentResponse.text();
    
    return {
      markdown,
      postInfo,
      release: data.release
    };
  };
  
  export const processMarkdownContent = (markdown, bucketUrl, release) => {
    const htmlContent = marked(markdown);
  
    // Fix image URLs in the content
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;
    
    const images = tempDiv.getElementsByTagName('img');
    Array.from(images).forEach(img => {
      const srcAttr = img.getAttribute('src');
      if (srcAttr && srcAttr.startsWith('/')) {
        img.setAttribute('src', `${bucketUrl}/releases/${release}${srcAttr}`);
      }
    });
  
    // Parse HTML content for headings
    const headings = tempDiv.querySelectorAll('h1, h2, h3, h4, h5, h6');
    
    const tocItems = Array.from(headings).map((heading, index) => {
      const id = `heading-${index}`;
      heading.id = id;
      return {
        id,
        text: heading.textContent,
        level: parseInt(heading.tagName.charAt(1))
      };
    });
  
    return {
      content: tempDiv.innerHTML,
      tocItems
    };
  };