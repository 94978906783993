import React from 'react';
import Layout from '../components/Layout';
import { FaGraduationCap, FaBriefcase, FaCloud, FaCode, FaChartBar } from 'react-icons/fa';
import '../styles/About.css';
import { ReactComponent as Logo } from '../assets/logo.svg';

export default function About() {
  return (
    <Layout>
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="hero-section">
          <div className="hero-logo-wrapper">
            <Logo className="hero-logo" />
          </div>
          <h1 className="hero-title">Marcus Webb</h1>
          <p className="hero-subtitle">Lead Machine Learning Engineer</p>
          <p className="hero-description">
            Proven machine learning engineer and team lead with 7+ years of experience building and implementing Data Science solutions. 
            Demonstrated success working with cross-functional teams implementing cloud-native AI applications and infrastructure. 
            Deep expertise across multiple cloud providers, languages and technologies.
          </p>
        </div>

        <div className="skills-grid">
          <div className="skill-card">
            <div className="skill-icon-wrapper blue">
              <FaCloud className="skill-icon blue" />
            </div>
            <h3 className="skill-title">Cloud Technologies</h3>
            <ul className="skill-list">
              {['Google Cloud Platform', 'Vertex AI', 'Kubernetes', 'Microsoft Azure', 'AzureML', 'Kubeflow', 'Docker'].map((tech) => (
                <li key={tech} className="skill-item">
                  <span className="skill-bullet blue"></span>
                  {tech}
                </li>
              ))}
            </ul>
          </div>

          <div className="skill-card">
            <div className="skill-icon-wrapper indigo">
              <FaCode className="skill-icon indigo" />
            </div>
            <h3 className="skill-title">Languages & Tools</h3>
            <ul className="skill-list">
              {['Python', 'Scala', 'Java', 'MATLAB', 'Gitlab CI', 'Terraform', 'React'].map((tech) => (
                <li key={tech} className="skill-item">
                  <span className="skill-bullet indigo"></span>
                  {tech}
                </li>
              ))}
            </ul>
          </div>

          <div className="skill-card">
            <div className="skill-icon-wrapper purple">
              <FaChartBar className="skill-icon purple" />
            </div>
            <h3 className="skill-title">Data Engineering</h3>
            <ul className="skill-list">
              {['Spark', 'PyTorch', 'Scikit-Learn', 'Databricks', 'SQL', 'Airflow', 'BigQuery'].map((tech) => (
                <li key={tech} className="skill-item">
                  <span className="skill-bullet purple"></span>
                  {tech}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="timeline">
          <div className="timeline-line"></div>
          
          <div className="timeline-item">
            <div className="timeline-content">
              <div className="timeline-left">
                <h3 className="timeline-title">Lead MLOps Engineer</h3>
                <p className="timeline-company blue">Evolve 24</p>
                <p className="timeline-date">2024 - Present</p>
              </div>
              <div className="timeline-icon-wrapper">
                <div className="timeline-icon-circle blue">
                  <FaBriefcase className="timeline-icon" />
                </div>
              </div>
              <div className="timeline-right">
                <p className="timeline-description">
                  Leading ML systems design and implementation for social media analytics and forecasting. 
                  Developing standardized MLOps pipelines leveraging Airflow, BigQuery and Spark to provide 
                  customers insights into their Social Media perception and resonance.
                </p>
              </div>
            </div>
          </div>

          <div className="timeline-item">
            <div className="timeline-content">
              <div className="timeline-left">
                <h3 className="timeline-title">Lead ML Engineer</h3>
                <p className="timeline-company blue">Kohl's</p>
                <p className="timeline-date">2022 - 2024</p>
              </div>
              <div className="timeline-icon-wrapper">
                <div className="timeline-icon-circle blue">
                  <FaBriefcase className="timeline-icon" />
                </div>
              </div>
              <div className="timeline-right">
                <p className="timeline-description">
                  Led ML Engineering team implementing high-scale models in search, recommendations, and marketing.
                  Developed MLOps infrastructure and managed cloud resources for data science solutions.
                  Key achievement: Deployed uplift model on Kohls.com targeting persuadable customers with limited-time offers.
                </p>
              </div>
            </div>
          </div>

          <div className="timeline-item">
            <div className="timeline-content">
              <div className="timeline-left">
                <h3 className="timeline-title">Machine Learning Engineer</h3>
                <p className="timeline-company blue">PointsBet</p>
                <p className="timeline-date">2021 - 2022</p>
              </div>
              <div className="timeline-icon-wrapper">
                <div className="timeline-icon-circle blue">
                  <FaBriefcase className="timeline-icon" />
                </div>
              </div>
              <div className="timeline-right">
                <p className="timeline-description">
                  Designed and implemented ML solutions within Azure for Search and Recommendations.
                  Promoted to Team Lead after 6 months. Key achievement: Deployed trending search algorithm
                  using streaming KDE model to identify trending events.
                </p>
              </div>
            </div>
          </div>

          <div className="timeline-item">
            <div className="timeline-content">
              <div className="timeline-left">
                <h3 className="timeline-title">ML Engineer & Statistician</h3>
                <p className="timeline-company blue">Dow Chemical Co.</p>
                <p className="timeline-date">2017 - 2021</p>
              </div>
              <div className="timeline-icon-wrapper">
                <div className="timeline-icon-circle blue">
                  <FaBriefcase className="timeline-icon" />
                </div>
              </div>
              <div className="timeline-right">
                <p className="timeline-description">
                  Developed enterprise-scale ML solutions in Azure for manufacturing processes.
                  Key achievement: Deployed Computer Vision model using YOLO architecture to detect
                  safety infractions in real-time.
                </p>
              </div>
            </div>
          </div>

          <div className="timeline-item">
            <div className="timeline-content">
              <div className="timeline-left">
                <h3 className="timeline-title">Ph.D Mathematics</h3>
                <p className="timeline-company purple">University of Nebraska</p>
                <p className="timeline-date">2010 - 2015</p>
              </div>
              <div className="timeline-icon-wrapper">
                <div className="timeline-icon-circle purple">
                  <FaGraduationCap className="timeline-icon" />
                </div>
              </div>
              <div className="timeline-right">
                <p className="timeline-description">
                  Advanced research in mathematics, laying the foundation for future work in machine learning and AI.
                </p>
              </div>
            </div>
          </div>

          <div className="timeline-item">
            <div className="timeline-content">
              <div className="timeline-left">
                <h3 className="timeline-title">MS Mathematics</h3>
                <p className="timeline-company purple">Texas State University</p>
                <p className="timeline-date">2008 - 2010</p>
              </div>
              <div className="timeline-icon-wrapper">
                <div className="timeline-icon-circle purple">
                  <FaGraduationCap className="timeline-icon" />
                </div>
              </div>
              <div className="timeline-right">
                <p className="timeline-description">
                  Master's degree in Mathematics
                </p>
              </div>
            </div>
          </div>

          <div className="timeline-item">
            <div className="timeline-content">
              <div className="timeline-left">
                <h3 className="timeline-title">BS Mathematics</h3>
                <p className="timeline-company purple">Texas A&M University</p>
                <p className="timeline-date">2003 - 2007</p>
              </div>
              <div className="timeline-icon-wrapper">
                <div className="timeline-icon-circle purple">
                  <FaGraduationCap className="timeline-icon" />
                </div>
              </div>
              <div className="timeline-right">
                <p className="timeline-description">
                  Bachelor's degree in Mathematics
                </p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </Layout>
  );
}