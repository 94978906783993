import { Link } from 'react-router-dom';
import '../../styles/Blog.css';

export default function BlogCard({ post }) {
  return (
    <Link 
      to={`/blog/${post.filename.replace('.md', '')}`}
      className="blog-card"
    >
      <article>
        <time className="blog-card-date">{post.date}</time>
        <div className="blog-card-image">
          {post.image_url ? (
            <img src={post.image_url} alt="" />
          ) : (
            <div className="blog-card-icon">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
              </svg>
            </div>
          )}
        </div>
        <div className="blog-card-content">
          <h2 className="blog-card-title">
            <span className="title-text">{post.title}</span>
          </h2>
          {post.excerpt && <p className="blog-card-excerpt">{post.excerpt}</p>}
        </div>
        <div className="blog-card-meta">
          <div className="blog-card-arrow">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
            </svg>
          </div>
        </div>
      </article>
    </Link>
  );
}