import React from 'react';
import { FaGitlab, FaLinkedin, FaEnvelope } from 'react-icons/fa';
import '../styles/Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h4>Connect</h4>
          <div className="social-links">
            <a href="https://gitlab.com/webbdev-public" target="_blank" rel="noopener noreferrer">
              <FaGitlab /> GitLab
            </a>
            <a href="https://www.linkedin.com/in/marcus-webb-6125a3186" target="_blank" rel="noopener noreferrer">
              <FaLinkedin /> LinkedIn
            </a>
          </div>
        </div>
        <div className="footer-section">
          <h4>Contact</h4>
          <p><FaEnvelope /> Email: marcus.webb@webbdev.llc</p>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Webb Development LLC. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
